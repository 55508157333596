




























































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {getPageAPIs, Util} from "@/common/util";
import GoodsListAPIClass from "@/common/api/goodsList";
export const pageApiList = getPageAPIs(GoodsListAPIClass);
const defaultForms = {
  name: '',
  price: '',
  category_id: '',
  bar_code: '',
  inventory: 1,
  inventory_warn_num: 1,
  wholesale_price: 0
}

@Component({})
export default class UserList extends Vue {
  private buyAddressApi: any = new GoodsListAPIClass();
  private sellShow: boolean = false;
  private ischeck_goods: number = 1;
  private forms: any = {
    ...defaultForms
  };
  private rules = {
    name: [
      {required: true, message: '请输入商品名称', trigger: 'change'},
      {min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'change'}
    ],
    inventory: [
      {validator: this.validateInventory, trigger: 'change'}
    ],
    inventory_warn_num: [
      {validator: this.validateInventoryWarnNum, trigger: 'change'}
    ],
  }

  private validateInventory(rule, value, callback) {
    if (!value || value < 1) {
      return callback(new Error('库存数量最少1'));
    } else if (value > 9999999) {
      callback(new Error('库存数量最多9999999'));
    } else {
      callback();
    }
  }

  private validateInventoryWarnNum(rule, value, callback) {
    if (Number(value) > (this.forms.inventory)) {
      callback(new Error('不能大于库存量'));
    } else {
      callback();
    }
  }

  private form: any = {
    goods_name: "",
  };

  private activated(): void {
    this.getTable();
  }

  private getTable(): void {
    this.buyAddressApi.goodslists(
        {
          ...this.form,
          page: this.tablePage.pageNo,
          limit: this.tablePage.pageSize,
        },
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.totalCount = res.data.count;
          this.tablePage.pageNo = res.data.page;
          this.tablePage.prepage = res.data.prepage;
        }
    );
  }

  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getTable();
  }

  private restForms(): void {
    this.forms = new Util().clearObject(this.forms);
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private category: any = [];

  private goodscategory(): void {
    this.buyAddressApi.goodscategory({}, (res: any) => {
      this.category = res.data.list;
    });
  }

  private title: string = "";

  private setGoods(type: string, row: any): void {
    console.log(row, '编辑数据')
    this.restForms();
    this.goodscategory();
    if (type === 'add') {
      this.title = "添加商品";
      this.forms = {
        ...defaultForms
      }
    } else {
      this.title = "编辑商品";
      this.forms = row;
    }
    this.sellShow = true;
  }

  private submit(): void {
    this.$refs['form'].validate((valid) => {
      if (valid) {
        if (this.title == "添加商品") {
          this.buyAddressApi.goodsadd(this.forms, () => {
            this.sellShow = false;
            Util.showToast("新增成功");
            this.search();
          });
        } else {
          this.buyAddressApi.goodssave(this.forms, () => {
            this.sellShow = false;
            Util.showToast("修改成功");
            this.search();
          });
        }
      } else {
        console.log('error submit!!');
        return false;
      }
    });

  }

  private goodsdelete(id: string, name: string): void {
    this.$confirm(`是否删除${name}?`, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
        .then(() => {
          this.buyAddressApi.goodsdelete(
              {
                id: id,
              },
              () => {
                Util.showToast("删除成功");
                this.search();
              }
          );
          // this.$message({
          //   type: "success",
          //   message: "删除成功!",
          // });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private highlightRow({row}) {
    if (row.is_highlight === 1) {
      return {'background-color': '#faaeb7'}
    }
  }

  private handleInputPrice(e: any) {
    this.forms.price = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null
  }

  private handleInputWholesalePrice(e: any) {
    this.forms.wholesale_price = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null
    if (this.forms.wholesale_price > 99999) {
      this.forms.wholesale_price = 99999
    } else if (this.forms.wholesale_price < 0) {
      this.forms.wholesale_price = 0
    }
  }

  private onInputWholesalePrice(value: any) {
    this.forms.wholesale_price = value.replace(/[^0-9.]/g, '')
    if (this.forms.wholesale_price > 99999) {
      this.forms.wholesale_price = 99999
    } else if (this.forms.wholesale_price < 0) {
      this.forms.wholesale_price = 0
    }
  }

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }
}
